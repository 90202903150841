import { ServerURL } from '../../config.js'

let subfolder = "career-path"

export const Config = {
	server: {
		"getData"					:	ServerURL+subfolder+"/getData.php",
	}

}
