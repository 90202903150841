export const roles_of_the_ecsf = [
    "A.1. IS and Business Strategy Alignment",
    "A.2. Service Level Management",
    "A.3. Business Plan Development",
    "A.4. Product / Service Planning",
    "A.5. Architecture Design",
    "A.6. Application Design",
    "A.7. Technology Trend Monitoring",
    "A.8. Sustainability Management",
    "A.9. Innovating",
    "A.10. User Experience",
    "B.1. Application Development",
    "B.2. Component Integration",
    "B.3. Testing",
    "B.4. Solution Deployment",
    "B.5. Documentation Production",
    "B.6. ICT Systems Engineering",
    "C.1. User Support",
    "C.2. Change Support",
    "C.3. Service Delivery",
    "C.4. Problem Management",
    "C.5. Systems Management",
    "D.1. Information Security Strategy Development",
    "D.2. ICT Quality Strategy Development",
    "D.3. Education and Training Provision",
    "D.4. Purchasing",
    "D.5. Sales Development",
    "D.6. Digital Marketing",
    "D.7. Data Science and Analytics",
    "D.8. Contract Management",
    "D.9. Personnel Development",
    "D.10. Information and Knowledge Management",
    "D.11. Needs Identification",
    "E.1. Forecast Development",
    "E.2. Project and Portfolio Management",
    "E.3. Risk Management",
    "E.4. Relationship Management",
    "E.5. Process Improvement",
    "E.6. ICT Quality Management",
    "E.7. Business Change Management",
    "E.8. Information Security Management",
    "E.9. Information Systems Governance"
  ]
  
  export const domains = [
    "Communication and Network Security",
    "Identity and access management (IAM)",
    "Security Architecture and Engineering",
    "Asset Security",
    "Security and Risk Management",
    "Security Assessment and Testing",
    "Software Security",
    "Security Operations"
  ]

  // CyberSecurity Skills ID list
  export const csSkillsSet = [1, 3, 4, 5, 8, 11, 12, 13, 14, 15, 16, 20, 24, 29, 30];

  // Other IT Skills ID list
  export const itSkillsSet = [6, 7, 10, 17, 18, 25, 27];

  // SOFT Skills ID list
  export const softSkillsSet = [2, 9, 19, 21, 22, 23, 26, 28, 31];