import { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea.js";
import { itSkillsSet, csSkillsSet, softSkillsSet } from "../Utils";

const AddEditSkillModalForm = ({
  editRecord,
  onCancel,
  onModalSubmit,
  rsSkillsGroup,
}) => {
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (
      typeof editRecord === "object" &&
      editRecord !== null &&
      form.getFieldValue("id") !== editRecord.id
    ) {
      let initialData = JSON.parse(JSON.stringify(editRecord));
      form.setFieldsValue(initialData);
      setIsNew(false);
    } else if (editRecord === "new") {
      setIsNew(true);
    }
  }, [editRecord, form]);

  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord !== null}
      title={isNew ? "Add a new skill" : "Edit skill"}
      width={850}
      okText="Save"
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      className={isNew ? "add-skill-modal" : "edit-skill-modal"}
      onOk={() => {
        form.validateFields().then((values) => {
          values.skills_group = values.it_skills_group.concat(
            values.cs_skills_group,
            values.sf_skills_group
          );
          delete values.it_skills_group;
          delete values.cs_skills_group;
          delete values.sf_skills_group;
          onModalSubmit(values);
        });
      }}
    >
      <Form
        preserve
        form={form}
        layout="horizontal"
        name={isNew ? "add-skill-modal-form" : "edit-skill-modal-form"}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
        labelWrap
        colon={false}
        className="content-scroll"
				initialValues={{
					name: '',
					description: '',
					it_skills_group: [], 
					cs_skills_group: [],
					sf_skills_group: [],
			}}
      >
        <Form.Item
          name="name"
          label="Title"
          rules={[
            { required: true, message: "Please enter the title" },
            { type: "string", min: 2, max: 1023 },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>

				<Form.Item  wrapperCol={{
          offset: 7,
          span: 15,
        }}>
					<Button >Analyze</Button>
				</Form.Item>

        <Form.Item name="it_skills_group" label="IT Skills">
          <Select
            showSearch
            showArrow
            allowClear
            mode="multiple"
            optionFilterProp={"label"}
            options={rsSkillsGroup
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .filter((e) => itSkillsSet.includes(e.id))
              .map((skill) => ({ value: skill.id, label: skill.name }))}
          />
        </Form.Item>

        <Form.Item name="cs_skills_group" label="CS Skills">
          <Select
            showSearch
            showArrow
            allowClear
            mode="multiple"
            optionFilterProp={"label"}
            options={rsSkillsGroup
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .filter((e) => csSkillsSet.includes(e.id))
              .map((skill) => ({ value: skill.id, label: skill.name }))}
          />
        </Form.Item>

        <Form.Item name="sf_skills_group" label="Soft Skills">
          <Select
            showSearch
            showArrow
            allowClear
            mode="multiple"
            optionFilterProp={"label"}
            options={rsSkillsGroup
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .filter((e) => softSkillsSet.includes(e.id))
              .map((skill) => ({ value: skill.id, label: skill.name }))}
          />
        </Form.Item>

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditSkillModalForm;
