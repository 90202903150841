import React from "react";
import { Avatar, Divider, Timeline, Typography, Form, Input, Button, Row, Col } from "antd";
import Axios from "axios";
import { Config } from "./config_section.js";

import "./Contact.scss";

import logo_vut from "../../assets/logo_vut.png";
import logo_kth from "../../assets/logo_kth.png";
import logo_tuc from "../../assets/logo_tuc.png";

const { Title } = Typography;

const Contact = ({showMessage}) => {

  const [contactModalLoading, setContactModalLoading] = React.useState(false);
  const [form] = Form.useForm();
  

  const handleSubmit = () => {
    form.validateFields()
    .then((values) => {
      setContactModalLoading(true);
      Axios.post(Config.server.sendMessage, values, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        setContactModalLoading(false);
        if (response && response.data.error === false){
          form.resetFields();
          showMessage({type: 'success', content: "Message sent successfully!"})
        } else {
          showMessage({type: 'error', content: "Message failed to send!"})
          console.log("Message failed to send!");
        }
      })
      .catch((error) => {
        setContactModalLoading(false);
        console.log(error);
        showMessage({type: 'error', content: "Message failed to send!"})
      })
    })
    .catch((info) => {
      console.log(info);
      })
  };

  return (
    <section id="contacts">
      <div className="section-container">
        <div className="text-content">
          <Title level={1} className="pageTitle">
            CyberABILITY Platform Team
          </Title>

          <Timeline mode="alternate">

            <Timeline.Item className="right" dot={<Avatar src={logo_vut} />}>
              <Title level={4}>Brno University of Technology</Title>
              Department of Telecommunications
              <br />
              Technicka 12
              <br />
              616 00 BRNO
              <br />
              Czech Republic
            </Timeline.Item>

            <Timeline.Item className="left" dot={<Avatar src={logo_kth} />}>
              <Title level={4}>KTH Royal Institute of Technology</Title>
              SE-100 44 Stockholm
              <br />
              Sweden
            </Timeline.Item>

            <Timeline.Item className="right" dot={<Avatar src={logo_tuc} />}>
              <Title level={4}>Technical University of Crete</Title>
              University Campus, Akrotiri
              <br />
              Chania 73100
              <br />
              Greece
            </Timeline.Item>
            
          </Timeline>
          {/* <Paragraph className={"demo"}>
            <Link href="https://www.youtube.com/watch?v=e_rmfbnvYu0" target="_blank">
              <Space size={"small"}>
                <YoutubeOutlined /> Cyber Security Profiler
              </Space>
            </Link>
            <br />
            <Link href="https://www.youtube.com/watch?v=ltFiBpvki6g" target="_blank">
              <Space size={"small"}>
                <YoutubeOutlined /> Job Ads Analyzer
              </Space>
            </Link>
          </Paragraph> */}

          <Divider><Title level={4}>CONTACT US</Title></Divider>
          <Form
            className="contact-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <Form.Item label="Name" name="name" rules={[{required: true, message: "Please enter your name!"}]}>
              <Input placeholder="Your name" />
            </Form.Item>
            <Form.Item label="E-mail" name="email" rules={[{required: true, message: "Please enter your email address!"}, {type: "email", message: "Please enter a valid email address!"}]}>
              <Input placeholder="Your email" />
            </Form.Item>
            <Form.Item label="Message" name="message" rules={[{required: true, message: "Please provide a message!"}]}>
              <Input.TextArea placeholder="Your message" rows={4}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={contactModalLoading}>Send</Button>
            </Form.Item>
          </Form>
          <Divider><Title level={4}>DEMO</Title></Divider>
            <Row justify="center" align={"middle"}> 
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div style={{display: "flex", justifyContent: "center", padding: 10}}>
                  <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/e_rmfbnvYu0?si=tdTyTMdkqSQ8P-o-" 
                    title="Cyber Security Profiler" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                  />  
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div style={{display: "flex", justifyContent: "center", padding: 10}} >
                  <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/ltFiBpvki6g?si=jA1XIVEPIsxbb115" 
                    title="Job Ads Analyzer" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    />  
                </div>
              </Col>
            </Row>
        </div>
      </div>
    </section>
  );
};

export default Contact;
