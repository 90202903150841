import React, { useEffect } from 'react'
import Axios from 'axios'
import {Avatar, Button, Layout, Menu, message, Modal, Popover, Typography  } from 'antd'
import { EditOutlined, HomeOutlined, QuestionCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import {createHash} from 'crypto'
import moment from 'moment'
import { useDeviceSelectors } from 'react-device-detect'
import { Switch, Route, Link as RouterLink } from 'react-router-dom'

import {Config} from './config_section.js'
import {SaveVisitorStats} from '../../config.js'
import './App.scss'
import logo from '../../assets/logo_white.png'
import LoginModal from './modals/LoginModal'
import RegisterModal from './modals/RegisterModal'
import UserEditModal from './modals/UserEditModal'
import ForgotPassModal from './modals/ForgotPassModal'
import FeedbackMain from './modals/FeedbackMain'
import FeedbackSection from './modals/FeedbackSection'

import Home from '../home/Home'
import Contact from '../contact/Contact.js'
import BlankSection from '../blank-section/BlankSection'
import CareerPath from '../career-path/CareerPath'
import JobAdsAnalyzer from '../job-ads-analyzer/JobAdsAnalyzer'
import CSProfiler from '../csprofiler/CSProfiler'
import Roles from '../roles/Roles'
import Admin from '../admin/Admin'
import SectionNotFound from '../section-not-found/SectionNotFound'
import CSPIntersection from '../csprofiler-intersection/CSPIntersection.js'
import CareerPathVut from '../career-path-vut/CareerPathVut.js'

const { Header, Content, Footer } = Layout
const { confirm } = Modal
const { Link } = Typography


const App = () => {

  // state hook
  const [visitorData, setVisitorData] = React.useState(null)
  const [visitorId, setVisitorId] = React.useState(localStorage.getItem('visitor_id'))
  const [askToFeedback, setAskToFeedback] = React.useState("feedback_main")
  const [user, setUser] = React.useState(null)
  const [loginModalVisible, setLoginModalVisible] = React.useState(false)
  const [registerModalVisible, setRegisterModalVisible] = React.useState(false)
  const [userEditModalVisible, setUserEditModalVisible] = React.useState(false)
  const [forgotPassModalVisible, setForgotPassModalVisible] = React.useState(false)
  const [section, setSection] = React.useState("home")
  const [uaSelectors, uaData] = useDeviceSelectors(window.navigator.userAgent)

  const sendVisitorData = async (dataToSend) => {
    if (dataToSend !== 0 && dataToSend !== null && SaveVisitorStats === true) {
      try {
        await fetch( Config.server.saveVisitor, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({...dataToSend, end_time: moment().utcOffset(1).format('YYYY-MM-DD HH:mm:ss')})
        })
      } catch (error) {
        console.error('Error saving visitor data:', error)
      }
    }
  }

  React.useEffect(() => {
    // mount hook

    // get visitor data
    Axios.get("https://ipapi.co/json")
    .then((response) => {
      let firstBatch = {
        start_time: moment().utcOffset(1).format('YYYY-MM-DD HH:mm:ss'),
        user_id: "",
        visitor_id: visitorId,
        sections: [],
        city: response.data.city,
        country: response.data.country_name,
        ip: response.data.ip,
        organization: response.data.org,
        os: uaData.os,
        browser: {name: uaData.browser.name, version: uaData.browser.version},
        device: (
          uaSelectors.isMobileOnly ? "Mobile" :
          uaSelectors.isTablet ? "Tablet" :
          uaSelectors.isDesktop ? "Desktop" :
          uaSelectors.isSmartTV ? "SmartTV" :
          uaSelectors.isWearable ? "Wearable" :
          uaSelectors.isConsole ? "Console" :
          uaSelectors.isEmbedded ? "Embedded" :
          "unknown"
        )
      }
      if (!visitorId) {
        const newVisitorId = createHash('sha256').update(firstBatch.ip + firstBatch.os + firstBatch.browser.name + firstBatch.device + firstBatch.start_time + Math.random()).digest('hex') 
        localStorage.setItem('visitor_id', newVisitorId)
        setVisitorId(newVisitorId)
        firstBatch.visitor_id = newVisitorId
      }
      if ( SaveVisitorStats === true ) {
        Axios.post( Config.server.saveVisitor, firstBatch, {headers: { 'Content-Type': 'application/json' }})
        .then((response) => {
          if (!response.data.error) {
            setVisitorData({sections: [section], id: response.data.data})
          } else {
            console.log(response.data.message)
            setVisitorData(0)
          }
        })
        .catch((err) => {
          console.log(err)
          setVisitorData(0)
        })
      } else {
        setVisitorData({sections: [section]})
      }      
    })
    .catch((err) => {
      console.log(err)
      setVisitorData(0)
      const newVisitorId = createHash('sha256').update(moment().utcOffset(1).format('YYYY-MM-DD HH:mm:ss') + Math.random()).digest('hex')
      localStorage.setItem('visitor_id', newVisitorId)
      setVisitorId(newVisitorId)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const handleBeforeUnload = () => {
      sendVisitorData(visitorData)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    // after visitorData change
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      // before visitorData change
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [visitorData])

  const authData = user ? {auth_a: user.id, auth_b: createHash('sha256').update(user.id + user.email + user.password + user.token).digest('hex')} : {auth_a: "", auth_b: ""}

  function showLogOutConfirm (id) {
    confirm({
      title: 'Are you sure you want to log out?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return new Promise((resolve, reject) => {
          Axios.post( Config.server.logout, authData, {headers: { 'Content-Type': 'application/json' }})
            .then((response) => {
              // logged out even in case of error (unauthorized access)
              showMessage({type: 'success', content: "Now you are logged out."})
              setUser(null)
              if (section.includes("admin")) setSection("home")
              resolve()
            })
            .catch((error) => {
              console.log(error)
              showMessage({type: 'error', content: "Something went wrong!"})
              setUser(null)
              if (section.includes("admin")) setSection("home")
              reject()
            })
        }).catch(() => {
          showMessage({type: 'error', content: "Something went wrong."})
        })
      }
    })
  }

  function login (userData) {
    setUser(userData)
    setVisitorData({...visitorData, user_id: userData.id})
    modalConfirm()
  }

  function getInitials () {
    let array = (user.name).split(" ")
    let output = ""
    array.forEach((item) => {
      output += item.charAt(0)
    })
    return output
  }

  function switchLogin (operation) {
    if (operation === "toReg"){
      setLoginModalVisible(false)
      setRegisterModalVisible(true)
    } else if (operation === "toLog"){
      setRegisterModalVisible(false)
      setLoginModalVisible(true)
    } else if (operation === "toForgot"){
      setLoginModalVisible(false)
      setForgotPassModalVisible(true)
    }
  }

  function modalConfirm () {
    setLoginModalVisible(false)
    setRegisterModalVisible(false)
    setUserEditModalVisible(false)
    setForgotPassModalVisible(false)
  }

  function showMessage (messageData) {
    switch (messageData.type){
      case 'info': message.info(messageData); break;
      case 'error': message.error(messageData); break;
      case 'loading': message.loading(messageData); break;
      case 'success': message.success(messageData); break;
      default: message.warning(messageData); break;
    }
  }

  const adminMenu = user && user.admin_level === 2 ?
    [{key: "admin", label: "Admin" , icon: <SettingOutlined />, className: section.includes("admin") ? "red" : "", children: [
      {key: 'admin-job-ads-stats', label: <RouterLink to="/admin/job-ads-statistics" style={{ textDecoration: 'none' }} >Job Ads Statistics</RouterLink>},
      {key: 'admin-certs', label: <RouterLink to="/admin/certifications" style={{ textDecoration: 'none' }} >Certifications</RouterLink>},
      {key: 'admin-study-programs', label: <RouterLink to="/admin/study-programs" style={{ textDecoration: 'none' }} >Study Programs</RouterLink>},
      {key: 'admin-trainings', label: <RouterLink to="/admin/trainings" style={{ textDecoration: 'none' }} >Trainings</RouterLink>},
      {key: 'admin-profiles', label: <RouterLink to="/admin/profiles" style={{ textDecoration: 'none' }} >Profiles</RouterLink>},
      {key: 'admin-skills', label: <RouterLink to="/admin/skills" style={{ textDecoration: 'none' }} >Skills Group</RouterLink>},
      {key: 'admin-users', label: <RouterLink to="/admin/users" style={{ textDecoration: 'none' }} >User Manager</RouterLink>},
      {key: 'admin-visitors-stats', label: <RouterLink to="/admin/visitors-statistics" style={{ textDecoration: 'none' }} >Visitors Statistics</RouterLink>}
    ]}] : []

  const UpdateSection = ({ section, setSection }) => {
    useEffect(() => {
      setSection(section)
    }, [section, setSection])
    return null
  }

  return (
    <Layout className="layout">
      <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
        <div className="header-container">
          <div className="logo"><Link href="https://rewireproject.eu/" target="_blank"><img src={logo} alt="rewire_logo"/></Link></div>
          <Menu
            disabledOverflow
            theme="dark"
            mode="horizontal"
            selectedKeys={[section]}
            items={[
              {key: "home", label: <RouterLink to="/" className="router-link" >Home</RouterLink>, icon: <HomeOutlined/>},
              {key: "submenu-job-ads-analyzer", label: "Job Ads Analyzer" , children: [
                {key: 'job-ads-analyzer', label: <RouterLink to="/job-ads-analyzer" style={{ textDecoration: 'none' }} >Job Ads Analyzer</RouterLink>},
                {key: 'job-ads-analyzer-create-your-ad', label: <RouterLink to="/job-ads-analyzer/create-your-ad" style={{ textDecoration: 'none' }} >Create your ad</RouterLink>},
                {key: 'job-ads-analyzer-statistics-job', label: <RouterLink to="/job-ads-analyzer/statistics-job" style={{ textDecoration: 'none' }} >Statistics</RouterLink>},
              ]},
              {key: "submenu-csprofiler", label: "CyberSecurity Profiler" , children: [
                {key: 'csprofiler-certs', label: <RouterLink to="/csprofiler/certifications" style={{ textDecoration: 'none' }} >Certifications</RouterLink>},
                {key: 'csp-intersection', label: <RouterLink to="/csp-intersection" style={{ textDecoration: 'none' }}>Curricula Designer</RouterLink>},
                // {key: 'csprofiler-curricula', label: <RouterLink to="/csprofiler/curricula" style={{ textDecoration: 'none' }}>Curricula</RouterLink>},
                // {key: 'csprofiler-study-profiler', label: <RouterLink to="/csprofiler/study-profiler" style={{ textDecoration: 'none' }} >Study Profiler</RouterLink>},
                {key: 'csprofiler-trainings', label: <RouterLink to="/csprofiler/trainings" style={{ textDecoration: 'none' }} >Trainings</RouterLink>},
                {key: 'csprofiler-statistics', label: <RouterLink to="/csprofiler/statistics" style={{ textDecoration: 'none' }} >Statistics</RouterLink>},
              ]},
              {key: "roles", label: <RouterLink to="/roles" style={{ textDecoration: 'none' }} >12 ECSF Roles</RouterLink>},
              {key: "career-path", label: <RouterLink to="/career-path" style={{ textDecoration: 'none' }} >Career Path/Development</RouterLink>},
              // {key: "career-path-vut", label: <RouterLink to="/career-path-vut" style={{ textDecoration: 'none' }} >Career Path/Development VUT</RouterLink>},
              {key: "contact", label: <RouterLink to="/contact" style={{ textDecoration: 'none' }} >Contact</RouterLink>},
              // {key: "blank-section", label: "Blank"},
              ...adminMenu
            ]}
            onClick={(e)=> {
              if (!e.key.includes("submenu")) {
                //setSection(e.key)
                if (visitorData.hasOwnProperty("sections")) {
                  let newVisitorData = JSON.parse(JSON.stringify(visitorData))
                  newVisitorData.sections.push(e.key)
                  setVisitorData(newVisitorData)
                }
              }
            }}
          />
          <div className="loginIcon">
            {user ?
              <>
                <Popover placement="bottomRight" id="user-profile" trigger="click" title={<><UserOutlined/> Your profile</>} content={
                  <div className="profileInfo">
                    <table><tbody>
                      <tr><td>Name:</td><td>{user.name}</td></tr>
                      <tr><td>Organization:</td><td>{user.company}</td></tr>
                      <tr><td>E-mail:</td><td>{user.email}</td></tr>
                      <tr><td>Password:</td><td>****</td></tr>
                      <tr><td></td><td></td></tr>
                    </tbody></table>
                    <div><Button type="outlined" onClick={()=>setUserEditModalVisible(user)}><EditOutlined className="editIcon" /> Edit</Button></div>
                  </div>
                }>
                  <Avatar className="userIcon logged" icon={getInitials() ? "" : <UserOutlined />} >{getInitials()}</Avatar>
                </Popover>
              </>
              :
              <Avatar className="userIcon" icon={<UserOutlined />} onClick={()=>setLoginModalVisible(true)} />
            }
            {user ? <Button ghost onClick={()=>showLogOutConfirm()}>Log out</Button> : <Button ghost onClick={()=>setLoginModalVisible(true)} >Log in</Button>}
          </div>
        </div>
      </Header>

      <Content className={"site-layout-content"}>

        <Switch>
          <Route exact path="/" render={() => <UpdateSection section="home" setSection={setSection} />} />
          <Route exact path="/roles" render={() => <UpdateSection section="roles" setSection={setSection} />} />
          <Route exact path="/contact" render={() => <UpdateSection section="contact" setSection={setSection} />} />
          <Route exact path="/career-path" render={() => <UpdateSection section="career-path" setSection={setSection} />} />
          <Route exact path="/career-path-vut" render={() => <UpdateSection section="career-path-vut" setSection={setSection} />} />
          <Route exact path="/blank-section" render={() => <UpdateSection section="blank-section" setSection={setSection} />} />
          <Route exact path="/job-ads-analyzer" render={() => <UpdateSection section="job-ads-analyzer" setSection={setSection} />} />
          <Route exact path="/job-ads-analyzer/create-your-ad" render={() => <UpdateSection section="job-ads-analyzer-create-your-ad" setSection={setSection} />} />
          <Route exact path="/job-ads-analyzer/statistics-job" render={() => <UpdateSection section="job-ads-analyzer-statistics-job" setSection={setSection} />} />
          <Route exact path="/csprofiler/certifications" render={() => <UpdateSection section="csprofiler-certs" setSection={setSection} />} />
          <Route exact path="/csprofiler/curricula" render={() => <UpdateSection section="csprofiler-curricula" setSection={setSection} />} />
          <Route exact path="/csprofiler/study-profiler" render={() => <UpdateSection section="csprofiler-study-profiler" setSection={setSection} />} />
          <Route exact path="/csprofiler/trainings" render={() => <UpdateSection section="csprofiler-trainings" setSection={setSection} />} />
          <Route exact path="/csprofiler/statistics" render={() => <UpdateSection section="csprofiler-statistics" setSection={setSection} />} />
          <Route exact path="/csp-intersection" render={() => <UpdateSection section="csp-intersection" setSection={setSection} />} />
          <Route exact path="/admin/certifications" render={() => <UpdateSection section="admin-certs" setSection={setSection} />} />
          <Route exact path="/admin/job-ads-statistics" render={() => <UpdateSection section="admin-job-ads-stats" setSection={setSection} />} />
          <Route exact path="/admin/study-programs" render={() => <UpdateSection section="admin-study-programs" setSection={setSection} />} />
          <Route exact path="/admin/trainings" render={() => <UpdateSection section="admin-trainings" setSection={setSection} />} />
          <Route exact path="/admin/profiles" render={() => <UpdateSection section="admin-profiles" setSection={setSection} />} />
          <Route exact path="/admin/skills" render={() => <UpdateSection section="admin-skills" setSection={setSection} />} />
          <Route exact path="/admin/users" render={() => <UpdateSection section="admin-users" setSection={setSection} />} />
          <Route exact path="/admin/visitors-statistics" render={() => <UpdateSection section="admin-visitors-stats" setSection={setSection} />} />
          <Route path="*" render={() => <UpdateSection section="section-not-found" setSection={setSection} />} />
        </Switch>

        {
          section.includes("job-ads-analyzer") ? (
            <JobAdsAnalyzer showMessage={showMessage} authData={authData} user={user} section={section}/>
          ) : section.includes("csprofiler") ? (
            <CSProfiler showMessage={showMessage} section={section}/>
          ) : section.includes("admin") ? (
            <Admin showMessage={showMessage} authData={authData} user={user} section={section} setSection={setSection} setLoginModalVisible={setLoginModalVisible}/>
          ) :section === "roles" ? (
            <Roles/>
          ) : section === "career-path" ? (
            <CareerPath/>
          ) : section === "career-path-vut" ? (
            <CareerPathVut showMessage={showMessage}/>
          ) : section === "blank-section" ? (
            <BlankSection/>
          ) : section === "section-not-found" ? (
            <SectionNotFound />
          ) : section === "contact" ? (
            <Contact showMessage={showMessage}/>
          ) : section === "csp-intersection" ? (
            <CSPIntersection />
          ) : <Home/>
        }

        {/* User Modals */ }
        <LoginModal visible={loginModalVisible} switchLogin={switchLogin} setUser={login} showMessage={showMessage} onCancel={()=>setLoginModalVisible(false)}/>
        <RegisterModal visible={registerModalVisible} switchLogin={switchLogin} onModalConfirm={modalConfirm} showMessage={showMessage} onCancel={()=>setRegisterModalVisible(false)}/>
        <UserEditModal setUser={setUser} visible={userEditModalVisible} onModalConfirm={modalConfirm} showMessage={showMessage} onCancel={()=>setUserEditModalVisible(false)} authData={authData} token={user ? user.token : ""} />
        <ForgotPassModal visible={forgotPassModalVisible} onModalConfirm={modalConfirm} showMessage={showMessage} onCancel={()=>setForgotPassModalVisible(false)} />
        
        {/* Feedback Modals */ }
        <FeedbackMain showMessage={showMessage} visitorId={visitorId} setAskToFeedback={(e)=>setAskToFeedback(e)} askToFeedback={askToFeedback}/>
        <FeedbackSection showMessage={showMessage} visitorId={visitorId} setAskToFeedback={(e)=>setAskToFeedback(e)} askToFeedback={askToFeedback}/>
        
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        REWIRE ©{new Date().getFullYear()}
      </Footer>
  </Layout>

  )
}

export default App