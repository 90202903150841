import React from "react";
import { Button, Col, Typography, Result, Row, Space, Spin } from "antd";
import Axios from "axios";
import { Config } from "./config_section.js";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import "./CareerPathVut.scss";
import AddEditSkillModalForm from "./AddEditSkillModalForm";

const { Title } = Typography;

const CareerPathVut = ( {showMessage }) => {
  const [skills, setSkills] = React.useState([]);
  const [editRecord, setEditRecord] = React.useState(null);
  const [rsSkillsGroup, setRsSkillsGroup] = React.useState(null)
  const [rsSpecificKnowledge, setRsSpecificKnowledge] = React.useState(null)
  const [rsSpecificSkills, setRsSpecificSkills] = React.useState(null)
  const [rsProfiles, setRsProfiles] = React.useState(null)

  React.useEffect(() => {
    getData()
  }, [])

  function getData () {
    // loading data state
    setRsSkillsGroup(null)
    setRsSpecificKnowledge(null)
    setRsSpecificSkills(null)
    setRsProfiles(null)
    Axios.get( Config.server.getData )
      .then((response) => {
        if (response && response.data.error === false){
          // success data state
          setRsSkillsGroup(response.data.data.rs_skill_group)
          setRsSpecificKnowledge(response.data.data.rs_specific_knowledge)
          setRsSpecificSkills(response.data.data.rs_specific_skills)
          setRsProfiles(response.data.data.rs_profiles)
        } else {
          console.log(response.data.message)
          // error data state
          setRsSkillsGroup(0)
          setRsSpecificKnowledge(0)
          setRsSpecificSkills(0)
          setRsProfiles(0)
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setRsSkillsGroup(0)
        setRsSpecificKnowledge(0)
        setRsSpecificSkills(0)
        setRsProfiles(0)
      })
  }

  const dataStatus = React.useMemo(()=> {
    if (
      rsSkillsGroup === null &&
      rsSpecificKnowledge === null &&
      rsSpecificSkills === null &&
      rsProfiles === null
    ) {
      return "loading"
    } else if (
      Array.isArray(rsSkillsGroup) &&
      Array.isArray(rsSpecificKnowledge) &&
      Array.isArray(rsSpecificSkills) &&
      Array.isArray(rsProfiles)
    ) {
      return "ok"
    } else {
      return "error"
    }
  }, [rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles])

  const onModalSubmit = (values) => {
    console.log(values)
    if (values.id === undefined) {
      console.log("new record")
      // todo: create unique id for new record to prevent conficts
      values.id = skills.length + 1
      setSkills([...skills, values])
    } else {
      console.log("edit existing record")
      setSkills(skills.map((item) => (item.id === values.id ? values : item)))
    }
    showMessage({ type: "success", content: "Skill saved successfully!" });
    setEditRecord(null)
  }

  return (
    <section id="career-path-vut">
      <div className="section-container">
        <div className="text-content">
          <Title level={1} className="pageTitle">
            Career Path
          </Title>
          {
            dataStatus === "loading" ? (
              <div className="vertCenter"><Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>
            ) : dataStatus === "error" ? (
              <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."
              />
            ) : (
              <>
                <Row>

                  <Col span={12}>
                    <Title level={2} className="sectionTitle">Your Skills</Title>
                    <div className="dataHandleButtons" >
                      <Space>
                        <Button onClick={()=>setEditRecord("new", false)}><PlusOutlined className="icon-position-fix"/> Add Certification</Button>
                      </Space>
                    </div>
                    
                    
                    <div className="list-container">
                      {skills.map((item, index) => (
                        <div className="list-item" key={index}>
                          {item.name}
                          <Button onClick={()=>setEditRecord(item)}> Edit</Button>
                        </div>
                      ))}
                    </div>
                  </Col>

                  <Col span={12}>
                    <Title level={2} className="sectionTitle">Statistics</Title>
                      TODO:  Tabulka pro profiles coverage
                  </Col>

                </Row>

                <AddEditSkillModalForm editRecord={editRecord} onCancel={()=>setEditRecord(null)} onModalSubmit={onModalSubmit} rsSkillsGroup={rsSkillsGroup} />
              </>
            )
          }          
        </div>
      </div>

      
    </section>
  );
};

export default CareerPathVut;
