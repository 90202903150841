import React from "react";
import { Config } from "../config_section.js";
import Axios from "axios";
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea.js";
import "../Admin.scss";
import moment from "moment";
import { countries, languages } from "countries-list";

const AddEditTrainingModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsProfiles, usersData, dbTrainings }) => {
    const [modalLoading, setModalLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [isNew, setIsNew] = React.useState(false);
    const [formDates, setFormDates] = React.useState([]);

    React.useEffect(() => {

        if (typeof editRecord === "object" && editRecord !== null && form.getFieldValue("id") !== editRecord.id) {
            let initialData = JSON.parse(JSON.stringify(editRecord));
            delete initialData.key;
            form.setFieldsValue(initialData);
            setFormDates(initialData.dates ? initialData.dates : []);
            setIsNew(false);
        } else if (editRecord === "new") {
            setIsNew(true);
            setFormDates([]);
        }
    }, [editRecord, form]);
    function getUserName(id) {
        let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers];
        let user = allUsers.find((u) => u.id === id);
        let output = user.admin_level > 1 ? "ADMIN " : "";
        output += user.name + " (ID " + user.id + ")";
        return output;
    }
    function nameExists(value) {
        return dbTrainings.find((e) => e.name === value) ? true : false;
    }

    const onDateChange = (date, index) => {
        const updatedDates = [...formDates];
        updatedDates[index] = date ? date.format("YYYY-MM-DD") : null;
        setFormDates(updatedDates);
        form.setFieldsValue({ dates: updatedDates });
    };

    const removeDatePicker = (index) => {
        const updatedDates = formDates.filter((_, i) => i !== index);
        setFormDates(updatedDates);
        form.setFieldsValue({ dates: updatedDates });
    };

    const addDatePicker = () => {
        setFormDates([...formDates, null]);
    };
    return (
        <Modal
            getContainer={false}
            centered
            open={editRecord !== null}
            title={isNew ? "Add a new training" : "Edit training"}
            width={850}
            okText="Save"
            confirmLoading={modalLoading}
            cancelText="Cancel"
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            className={isNew ? "add-training-modal" : "edit-training-modal"}
            onOk={() => {
                form.validateFields().then((values) => {
                    setModalLoading(true);
                    delete values.created;
                    delete values.created_by;
                    delete values.updated;
                    delete values.updated_by;

                    if (isNew) {
                        const reqData = {
                            name: values.name,
                            organizer: values.organizer || "",
                            description: values.description || "",
                            country: values.country || 0,
                            duration: values.duration || 0,
                            type_format: values.type_format || "",
                            timing: values.timing || "",
                            dates: values.dates || [],
                            content_type: values.content_type || "",
                            prerequisites: values.prerequisites || "",
                            can_lead_to_certification: values.can_lead_to_certification || "",
                            includes_exams_for_certification: values.includes_exams_for_certification || "",
                            language: values.language || "",
                            price: values.price || 0,
                            skills_group: values.skills_group || [],
                            specific_skills: [],
                            specific_knowledge: [],
                            link: values.link ? values.link : "",
                        };

                        Axios.post(Config.server.addTraining, { ...authData, ...reqData }, { headers: { "Content-Type": "application/json" } })
                            .then((response) => {
                                setModalLoading(false);
                                if (response && response.data.error === false) {
                                    form.resetFields();
                                    showMessage({
                                        type: "success",
                                        content: "New study program successfully added.",
                                    });
                                    refresh();
                                } else {
                                    console.log("error: " + response.data.message);
                                    showMessage({
                                        type: "error",
                                        content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!",
                                    });
                                }
                            })
                            .catch((error) => {
                                setModalLoading(false);
                                console.log(error);
                                showMessage({
                                    type: "error",
                                    content: "Something went wrong!",
                                });
                            });
                    } else {
                        Axios.post(Config.server.editTraining, { ...authData, ...values }, { headers: { "Content-Type": "application/json" } })
                            .then((response) => {
                                setModalLoading(false);
                                if (response && response.data.error === false) {
                                    form.resetFields();
                                    showMessage({
                                        type: "success",
                                        content: "Updated successfully.",
                                    });
                                    refresh();
                                } else {
                                    console.log("error: " + response.data.message);
                                    showMessage({
                                        type: "error",
                                        content: "Something went wrong!",
                                    });
                                }
                            })
                            .catch((error) => {
                                setModalLoading(false);
                                console.log(error);
                                showMessage({
                                    type: "error",
                                    content: "Something went wrong!",
                                });
                            });
                    }
                });
            }}
        >
            <Form form={form} layout="horizontal" name="addedit-training-form" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} labelWrap colon={false} className="content-scroll">
                <Form.Item
                    name="name"
                    label="Training name"
                    rules={[
                        { required: true, message: "Please enter the title" },
                        { type: "string", min: 2, max: 1023 },
                        () => ({
                            validator(_, value) {
                                if (!value || !nameExists(value) || !isNew) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("This training is already stored in the database!"));
                            },
                        }),
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="organizer"
                    label="Organizer"
                    rules={[
                        {
                            required: true,
                            message: "Please enter the organizer",
                        },
                        { type: "string", min: 2, max: 1023 },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                        {
                            required: true,
                            message: "Please enter the description",
                        },
                        { type: "string", min: 2, max: 1023 },
                    ]}
                >
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item name="country" label="Country" rules={[{ required: true, message: "Please select country." }]}>
                    <Select showSearch showArrow allowClear>
                        {Object.values(countries).map((country, index) => (
                            <Select key={index} value={country.name}>
                                {country.name}
                            </Select>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="duration" label="Duration (hours)" rules={[{ required: true, message: "Please enter the title" }]}>
                    <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="type_format"
                    label="Format"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Please select one option",
                        },
                    ]}
                >
                    <Select showSearch showArrow allowClear>
                        <Select.Option value="Face-to-face" label="Face-to-face" />
                        <Select.Option value="Online" label="Online" />
                        <Select.Option value="Hybrid" label="Hybrid" />
                    </Select>
                </Form.Item>
                <Form.Item
                    name="timing"
                    label="Timing"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Please select one option",
                        },
                    ]}
                >
                    <Select showSearch showArrow allowClear>
                        <Select.Option value="Fixed dates" label="Fixed dates" />
                        <Select.Option value="Available online" label="Available online" />
                        <Select.Option value="On demand" label="On demand" />
                    </Select>
                </Form.Item>
                <Form.Item name="dates" label="Dates">
                    <Space direction="vertical">
                        {formDates.map((date, index) => (
                            <Space key={index} align="baseline">
                                <DatePicker value={date ? moment(date, "YYYY-MM-DD") : null} onChange={(date) => onDateChange(date, index)} />
                                <Button onClick={() => removeDatePicker(index)}>Remove</Button>
                            </Space>
                        ))}
                        <Button type="dashed" onClick={addDatePicker}>
                            Add Date
                        </Button>
                    </Space>
                </Form.Item>
                <Form.Item
                    name="content_type"
                    label="Content type"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Please select one option",
                        },
                    ]}
                >
                    <Select showSearch showArrow allowClear>
                        <Select.Option value="Theoretical only" label="Theoretical only" />
                        <Select.Option value="Hands on" label="Hands on" />
                        <Select.Option value="Theoretical and hands on" label="Theoretical and hands on" />
                    </Select>
                </Form.Item>
                <Form.Item name="prerequisites" label="Prerequisites" rules={[{ type: "string", max: 1023 }]}>
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item
                    name="can_lead_to_certification"
                    label="Can lead to certification"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Please select one option",
                        },
                        { type: "string", min: 2, max: 1023 },
                    ]}
                >
                    <Select showSearch showArrow allowClear>
                        <Select.Option
                            value="Yes (based on specific standarts, recognized by national/internation organizations)"
                            label="Yes (based on specific standarts, recognized by national/internation organizations)"
                        />
                        <Select.Option value="No" label="No" />
                        <Select.Option value="Other (eg. certificate of attendance / participation)" label="Other (eg. certificate of attendance / participation)" />
                    </Select>
                </Form.Item>
                <Form.Item
                    name="includes_exams_for_certification"
                    label="Includes exams for certification"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Please select one option",
                        },
                        { type: "string", min: 2, max: 1023 },
                    ]}
                >
                    <Select showSearch showArrow allowClear>
                        <Select.Option value="Yes" label="Yes" />
                        <Select.Option value="No" label="No" />
                    </Select>
                </Form.Item>
                <Form.Item name="language" label="Language" rules={[{ required: true, message: "Please select at least one language." }]}>
                    <Select showSearch showArrow allowClear mode="multiple">
                        {Object.values(languages).map((language, index) => (
                            <Select key={index} value={language.name}>
                                {language.name}
                            </Select>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="price"
                    label="Price"
                    rules={[
                        { required: true, message: "Please provide price." },
                        { type: "int", min: 0 },
                    ]}
                >
                    <InputNumber addonAfter="€" min={0} style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item name="skills_group" label="REWIRE Skills" rules={[{ type: "array", max: 255 }]}>
                    <Select
                        showSearch
                        showArrow
                        allowClear
                        mode="multiple"
                        optionFilterProp={"label"}
                        options={rsSkillsGroup
                            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                            .map((skill) => ({
                                value: skill.id,
                                label: skill.name,
                            }))}
                    />
                </Form.Item>
                <Form.Item name="link" label="Link" rules={[{ type: "string", required: true, message: "Please enter link." }]}>
                    <Input />
                </Form.Item>

                {typeof editRecord === "object" && editRecord !== null ? (
                    <>
                        <Form.Item label="Updated">
                            {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
                        </Form.Item>

                        <Form.Item label="Created">
                            {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
                        </Form.Item>
                    </>
                ) : null}

                <Form.Item name="id" label="Id" hidden>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddEditTrainingModalForm;
