import React from 'react'
// import Axios from 'axios'
import { Button, /*Modal,*/ Space, Table, Tooltip, Typography  } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined, /*QuestionCircleOutlined*/ } from '@ant-design/icons'
import AddEditStudyPRogramModalForm from './modals/AddEditStudyProgramModalForm'

// import {Config} from './config_section.js'

// const { confirm } = Modal
const { Title, /*Text*/ } = Typography

const StudyPrograms = ({ refresh, showMessage, authData, usersData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, dbStudyPrograms }) => {
  const [editRecord, setEditRecord] = React.useState(null)

  function showDeleteConfirm (id) {
    /*
    confirm({
      title: 'Are you sure you want to delete this record?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return new Promise((resolve, reject) => {
          Axios.post( Config.server.deleteSkillsGroup, {...authData, id: id}, {headers: { 'Content-Type': 'application/json' }})
            .then((response) => {
              if (response && response.data.error === false){
                showMessage({type: 'success', content: "Record successfully deleted."})
                refresh()
                resolve()
              } else {
                showMessage({type: 'error', content: "Something went wrong."})
                reject()
              }
            })
            .catch((error) => {
              console.log(error)
              showMessage({type: 'error', content: "Something went wrong."})
              reject()
            })
        }).catch(() => {
          showMessage({type: 'error', content: "Something went wrong."})
        })
      }
    })
    */
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '48px',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'University',
      dataIndex: 'university',
      key: 'university',
      sorter:  (a, b) => a.university.localeCompare(b.university),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Degree',
      dataIndex: 'degree',
      key: 'degree',
      sorter:  (a, b) => a.degree.localeCompare(b.degree),
    },
    {
      title: 'Study Program',
      dataIndex: 'name',
      key: 'name',
      sorter:  (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Updated [CET]',
      dataIndex: 'updated',
      key: 'updated',
      width: '160px',
      sorter:  (a, b) => a.updated.localeCompare(b.updated),
    },
    {
      title: 'Created [CET]',
      dataIndex: 'created',
      key: 'created',
      width: '160px',
      sorter:  (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      render: (record) => (
        <Space size="small">
          <Tooltip title="Edit row"><EditOutlined className="editIcon" onClick={() => setEditRecord(dbStudyPrograms.find(obj => obj.id === record.id))} /></Tooltip>
          <Tooltip title="Delete row"><DeleteOutlined className="deleteIcon" onClick={() => showDeleteConfirm(record.id)} /></Tooltip>
        </Space>
      ),
    }
  ]

  return (
    <div id="study-programs">
      <Title className="pageTitle">Study Programs</Title>

      <div className="dataHandleButtons" >
        <Space>
          <Button onClick={()=>setEditRecord("new")}><PlusOutlined className="icon-position-fix"/> Add Study Program</Button>
        </Space>
      </div>
      <Table size="small" className="data-table"
        dataSource={dbStudyPrograms}
        pagination={false}
        columns={tableColumns}
      />

      {/* modals */}

      <AddEditStudyPRogramModalForm editRecord={editRecord} refresh={refresh} showMessage={showMessage} onCancel={()=>setEditRecord(null)} authData={authData} rsSkillsGroup={rsSkillsGroup} usersData={usersData} dbStudyPrograms={dbStudyPrograms} />
    </div>
  )
}

export default StudyPrograms
